.cart-payment-type-wrapper {
  > h4 {
    margin-left: 15px;
    // font-family: var(--modals_heading);
    font-family: var(--modals_description);
  }
  > p {
    margin-left: 15px;
    font-family: var(--modals_description);
  }
  > .cart-payment-row {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    max-height: calc(100vh - 250px);
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // flex-wrap: wrap;
    > .cart-payment-type {
      margin: 5px;
      padding: 5px 2px 3px 12px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      height: 75px;
      // width: 130px;
      background-color: white;
      > img {
        width: 100px;
      }
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transform: scale(1.01);
      }
      &.selected {
        border: 1px solid var(--primary_button_color);
      }
    }
    > .stripe {
      color: #4caf50;
    }
    > .cash {
      color: red;
    }
    > .paypal {
      color: #0015ff;
    }
  }
  > .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    > button {
      width: 50%;
      margin-top: 10px;
    }
    > .cancel-button {
      padding: 10px 25px;
      font-family: "DM Sans", Sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #a7a4a4;
      background-color: transparent;
      //   border: 1px solid #d1d1d1;
      border: none;
      border-radius: 4px;
      outline: 0;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
}
