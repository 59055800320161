

.rating-holder {
    display: flex;
    justify-content: center;
}

.rating-bar {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 17rem;
}

.rating-icon {
    align-items: center;
    display: flex;
    color: #009688;
    cursor: pointer;
    font-size: 1.2rem;
    justify-content: center;
    padding: 0;
    transition: all 0.5s ease-in-out;
    height: 3rem;
    width: 3rem;
}

.rotate {
    transform: rotate(144deg);
}