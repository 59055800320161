.menuPage-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // justify-content: center;
  align-items: center;
  background-color: var(--background_color);
  min-height: 100vh;
  > .foods-category-wrapper {
    display: flex;
    margin-top: 32px;
    flex: 1;
    > .foods-wrapper {
      // flex: 1;
      width: 100%;
      padding: 10px;
      height: 100%;
      > .no-data-container {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;

        align-items: center;
        > img {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
}
