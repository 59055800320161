.hot-action-item-container {
  display: flex;
  height: 9em;
  width: 100%;
  padding: 12px;
  margin: 0.8rem 0rem;
  background-color: var(--background_color);
  border-radius: 8px;
  box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
    rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
  cursor: pointer;

  > .hot-action-image-container {
    width: 28%;
    display: flex;
    margin-right: 1em;
    align-items: center;
    align-self: center;
    height: 100%;

    img {
      width: 100%;
      min-height: 10px;
      position: relative;
      border-radius: 2px;
    }
  }

  > .hot-action-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 72%;

    > .hot-action-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > .hot-action-name {
        font-family: var(--hot_action_requests_item_header);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        margin-right: 1px;
        font-weight: 800;
      }

      > .hot-action-message {
        font-family: var(--hot_action_requests_item_message);
        font-size: 0.8em;
      }
    }

    > .send-request-container {
      margin-left: auto;

      > .send-request-button {
        border: none;
        border-radius: 0.3em;
        padding: 0.5em 0.9em;
        font-size: 0.9em;
        font-weight: 700;
        color: white;
        background: #5ac68c;
      }
    }
  }

  > .bell-icon-container {
    width: 5%;
    display: flex;
  }
}
.hot-action-item-container.active {
  border: 1.5px solid #9e9e9e;
}

@media (max-width: 768px) {
  .hot-action-item-container {
    height: 9em;
    margin: 0.5rem 0rem;
    padding: 13px;

    > .hot-action-info-container {
      > .hot-action-text-container {
        > .hot-action-name {
          font-size: 1.3em;
        }

        > .hot-action-message {
          font-size: 1em;
          line-height: 17px;
          margin: 0;
        }
      }
      > .send-request-container {
        > .send-request-button {
          padding: 0.6em 0.9em;
          font-size: 0.9em;
          font-weight: 700;
        }
      }
    }

    > .bell-icon-container {
      width: 5%;
    }
  }
}

@media (max-width: 640px) {
  .hot-action-item-container {
    height: 8em;
    margin: 0.3rem 0rem;
    padding: 8px;

    > .hot-action-info-container {
      > .hot-action-text-container {
        > .hot-action-name {
          font-size: 0.8em;
        }

        > .hot-action-message {
          font-size: 0.7em;
          line-height: 12px;
          margin: 0;
        }
      }
      > .send-request-container {
        > .send-request-button {
          padding: 0.5em 0.9em;
          font-size: 0.8em;
          font-weight: 700;
        }
      }
    }

    > .bell-icon-container {
      width: 0;
      display: none;
    }
  }
}
