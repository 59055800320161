.reset-password-page-wrapper {
  min-height: 100vh;
  width: 100%;
  padding: 20px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background_color);
  > .close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 32px;
    padding: 3px;
    border: 1px solid var(--primary_text_color);
    border-radius: 50%;
    cursor: pointer;
    color: var(--primary_text_color);
  }
  > .lock-icon {
    font-size: 48px;
    margin-bottom: 16px;
    color: var(--primary_button_color);
  }
  > h1 {
    cursor: pointer;
    font-size: 40px;
    // color: grey;
    font-family: var(--auth_heading);
  }

  > form {
    > h4 {
      text-align: left;
      width: 100%;
      margin-top: 32px;
      margin-bottom: 16px;
      font-family: var(--auth_sub_heading);
    }
    > .error-message {
      color: red;
      background-color: rgba(255, 0, 0, 0.212);
      padding: 5px 10px;
      border-radius: 4px;
      margin: 0;
      text-align: left;
      margin-bottom: 8px;
      font-family: var(--auth_description);
    }
    width: 400px;
    > div {
      display: flex;
      flex-direction: column;
      > p {
        margin: 0;
        margin-bottom: 7px;
        margin-top: 0;
        font-family: var(--auth_description);
      }
      > .label {
        margin-top: 15px;
        color: var(--primary_text_color);
      }
      > input {
        height: 40px;
        outline: 0;
        border-radius: 7px;
        border: 1px solid var(--primary_text_color);
        padding: 10px;
        margin-bottom: 5px;
        background-color: transparent;
        font-family: var(--auth_description);
      }
      > .error {
        > p {
          color: red;
        }
      }
    }
    > button {
      margin-top: 16px;
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
@media (max-width: 500px) {
  .reset-password-page-wrapper > form {
    width: 300px;
  }
}
@media (max-width: 330px) {
  .reset-password-page-wrapper > form {
    width: 250px;
  }
}
