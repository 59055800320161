@import "../../../variables";
.contactUs-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;

  > .contents {
    display: flex;
    width: 100%;
    max-width: $appMaxWidth;
    justify-content: space-between;
    > .email-sub-container {
      padding: 17px;
      border-radius: 5px;
      background-color: #a5a2a221;
      > .title {
        text-align: center;
        > h4 {
          font-family: var(--newsletter_section_heading);
          word-break: break-word;
        }
        > p {
          font-family: var(--newsletter_section_sub_heading);
          word-break: break-word;
        }
      }
    }
    > div {
      // width: 49%;
      display: flex;
      flex-direction: column;
      align-items: center;
    
      > h5 {
        color: var(--secondary_text_color);
        margin-bottom: 8px;
        width: 100%;
        font-family: var(--contact_us_section_sub_heading);
      }
      > h2 {
        margin-bottom: 16px;
        width: 100%;
        font-family: var(--contact_us_section_heading);
      }
      > h4 {
        text-align: left;
        width: 95%;
        margin-bottom: 16px;
        font-family: var(--contact_us_section_heading);
      }
      > p {
        font-family: var(--contact_us_section_description);
      }
      > div {
        display: flex;
        flex-direction: column;
        // width: 90%;
       

        > div {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          // width: 100%;
          > div {
            display: flex;
            align-items: center;
            > .icon {
              // font-size: 30px;
              width: 50px;
              height: 50px;

              padding: 15px;
              background-color: var(--primary_button_color);
              color: white;
              border-radius: 30px;
              margin-right: 24px;
            }
            > div {
              // width: 95%;
              > h4 {
                font-family: var(--contact_us_section_heading);
              }
              > p {
                font-family: var(--contact_us_section_description);
              }
            }
          }
        }
      }
      > form {
        width: 95%;
        .col-section {
          margin-top: 32px;
          > h4 {
            margin-bottom: 12px;
            font-family: var(--newsletter_section_heading);
          }
          > input,
          textarea {
            width: 100%;
            height: 50px;
            // background-color: #ffffff;
            border: none;
            padding: 0px 15px 0px 15px;
            // border: none;
            outline: 0;
            font-family: var(--newsletter_section_placeholder);
            border-radius: 7px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            &.error {
              border: 1px solid red;
            }
          }
          > textarea {
            min-height: 100px;
            padding: 15px;
          }
          > p {
            width: 100%;
            font-size: 12px;
            text-align: end;
            padding: 5px 10px;
          }
        }
        > button {
          margin-top: 16px;
          min-width: 150px;
          > .icon {
            font-size: 24px;
            line-height: 5px;
            border: 1px solid white;
            border-radius: 15px;
          }
        }
        > .btn-container {
          margin-top: 20px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  > .contents.display-in-center {
    justify-content: center;

    > div {
      width: 100%;
      > h5 {
        text-align: center;
      }
      > h2 {
        text-align: center;
      }

      > div {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
@media screen and (min-width: 860px) {
  > .row-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width: 47%;
    }
  }
}
@media screen and (max-width: 1350px) {
  .contactUs-wrapper {
    display: flex;
    justify-content: space-evenly;
    > div {
      width: 90%;
      padding: 20px;
      > div {
        > div {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          > div {
            margin-bottom: 10px;
            > div {
              // width: 300px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .contactUs-wrapper {
    display: flex;
    margin-top: 1rem;

    > .contents {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div {
        width: 100%;

        > div {
          width: 100%;
          > div {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            > div {
              margin-bottom: 10px;
            }
          }
        }
        > h4 {
          width: 100%;
        }
      }
      > div {
        > form {
          width: 100%;
          > .row-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > div {
              width: 100%;
            }
          }
          .col-section {
            margin-top: 32px;
            > h6 {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
    > .contents.display-in-center {
      > div {
        > div {
          flex-direction: column;
          > div {
            width: 100%;
          }
        }
      }
    }
  }
}
