.OrderSuccess {
  text-align: center;
  > .checkIcon {
    margin-top: 64px;
    font-size: 40px;
    background-color: var(--primary_button_color);
    border-radius: 50%;
    color: white;
    height: 55px;
    // margin-top: -60px;
    width: 55px;
  }
  > h4 {
    font-family: var(--modals_heading);
  }
  > p {
    font-family: var(--modals_description);
  }
  > button {
    margin-top: 32px;
    width: 100%;
  }
}
