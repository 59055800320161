@import "../../../variables";
.home-featured-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  > .content {
    // margin-top: 64px;
    margin-top: 2rem;
    width: 100%;
    width: calc(100% - 20px);
    max-width: $appMaxWidth;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    > hr {
      margin-top: 32px;
      width: 100px;
      border: 2px solid var(--primary_button_color);
    }
    > h2 {
      font-family: var(--most_popular_foods_section_heading);
    }
    > p {
      max-width: 500px;
      margin-top: 16px;
      padding: 0px 10px;
      font-family: var(--most_popular_foods_section_description);
    }
    > div {
      width: 100%;
      // margin-bottom: 64px;
      margin-bottom: 1rem;
      min-height: 10rem;
      .featured-food-carousel {
        padding-bottom: 10px;
        .react-multi-carousel-dot-list {
          top: 0px;
        }
        @media screen and (max-width: 400px) {
          padding-top: 25px;
        }
      }
      .foodcard-wrapper {
        margin-left: auto;
        margin-right: auto;
        // padding: 10px;
        .weight {
          > .value {
            display: flex;
          }
        }
        .price,
        hr {
          display: none;
        }
        @media screen and (max-width: 1130px) {
          width: 95%;
        }
        @media screen and (max-width: 990px) {
          width: 95%;
          > img {
            max-height: 150px;
            // object-fit: cover;
          }
        }
        // @media screen and (max-width: 500px) {
        //   > img {
        //     max-height: 130px;
        //   }
        // }
        @media screen and (max-width: 400px) {
          display: flex;
          width: 100%;
          height: 100%;
          margin-top: 0px;
          padding-bottom: 0px;
          box-shadow: none;
          border: 1px solid rgba(128, 128, 128, 0.164);
          // height: 100px;
          .price,
          hr {
            display: flex;
          }
          > img {
            width: 38%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 0px;
          }
          > .body {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > .weight {
              display: none;
            }
            > hr {
              display: none;
            }
            > div {
              padding: 0;
            }
            > .price {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
