.discountCard {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin: 0.8rem 0rem;
  background-color: #f0f0f0;
  // background-color: var(--background_color);
  border-radius: 8px;
  // box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
  //   rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
  position: relative;
  > .close-icon {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 22px;
    padding: 3px;
    border: none;
    border-radius: 50%;
    background-color: #ce2929;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #a3a3a3;
    }
  }
  > .right {
    width: 100%;
    align-content: center;
    align-items: center;
    // padding-left: 14px;
    > .conditions-box {
      background-color: #80808029;
      padding: 10px;
      margin-top: 10px;
      border-radius: 5px;
      > .heading {
        font-size: small;
        font-weight: 500;
      }
      > .condition {
        font-size: smaller;
        >.icon{
          color: #5ac15e;
          margin-right: 5px;
          margin-bottom: 2px;
        }
      }
    }
    > .food-details {
      display: flex;
      justify-content: space-between;
      > div > .discount {
        > .name {
          font-size: 14px;
          font-weight: 700;
          color: #4d4d4d;
        }
        > .value {
          background-color: #5ac15e;
          border-radius: 10px;
          padding: 1px 5px 1px 5px;
          font-size: 12px;
          font-weight: 700;
          color: #fff;
          width: fit-content;
          > span svg {
            margin-bottom: 4px;
            margin-right: 2px;
          }
        }
      }
      > .price {
        font-family: var(--food_card_sub_heading);
      }
      > .name {
        font-family: var(--food_card_sub_heading);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        margin-right: 1px;
      }
    }
    > p {
      margin: 0;
      font-family: var(--food_card_description);
    }
  }
  > .left {
    width: 20%;
    display: flex;
    align-items: center;
    align-self: center;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      min-height: 10px;
      position: relative;
      object-fit: cover;
      border-radius: 2px 35px 35px 2px;
    }
  }
}
