.signout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .modal-content {
    max-width: 300px;
    border-radius: 7px !important;
    background-color: var(--background_color);
    > .inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;
      > .icon {
        font-size: 32px;
        margin-bottom: 16px;
        color: var(--primary_button_color);
      }
    }
    > .button-container {
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      > button {
        width: 49%;
        border: 1px solid rgba(128, 128, 128, 0.226);
        // color: rgba(128, 128, 128, 0.514);
        &:hover {
          // color: white;
        }
      }
    }
  }
}
