@import './../../../variables';
.menu-hero-section-wrapper {
  // padding-top: 60px;
  width: 100%;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  // min-height: 200px;
  // background-color: black;
  > img {
    width: 100%;
    object-fit: contain;
  }
  > .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // background-color: rgba(68, 67, 67, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      > .text-section {
        width: 100%;
        max-width: $appMaxWidth;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //   display: flex;
        h5 {
          font-weight: 500;
          color: white;
          // text-shadow: 10px 10px 10px black;
        }
        > h1 {
          // max-width: 45%;
          padding: 10px 0;
          color: white;
          text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.685),
            0px -4px 10px rgba(49, 48, 48, 0.747);
          // color: black;
          font-weight: 700;
          font-family: var(--hero_section_heading);
          @media screen and (max-width: 700px) {
            display: none;
          }
        }

        > h4 {
          font-weight: 700;
          max-width: 600px;
          font-family: var(--hero_section_sub_heading);
          // display: none;
          color: rgba(255, 255, 255, 0.747);
          text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.685),
            0px -4px 10px rgba(49, 48, 48, 0.747);
          @media screen and (max-width: 700px) {
            display: none;
          }
        }
        > .search-wrapper-outer {
          background-color: var(--background_color);
          border-radius: 24px;
          width: 100%;
          display: flex;
          padding: 10px 10px;
          margin: 10px 0px;
          max-width: 700px;
          // border: 1px solid var(--primary_text_color);
          // background-color: white;
          > form {
            display: flex;
            align-items: center;
            width: 100%;
            > button {
              border: none;
              background-color: transparent;
              > .search-icon {
                margin: 0px 10px;
                width: 20px;
                height: 20px;
                object-fit: cover;
                color: var(--primary_text_color);
              }
            }
            > input {
              border: none;
              height: 30px;
              background-color: var(--background_color);
              border-radius: 16px;
              font-size: 14px;
              width: 100%;
              outline: 0;
              font-family: var(--hero_section_description);
            }
          }

          @media screen and (max-width: 700px) {
            display: flex;
            margin: 5px;
            width: calc(100% - 10px);
          }
        }
        // > .search-wrapper-outer {
        //   width: 100%;
        //   max-width: 700px;
        //   > form {
        //     width: 100%;

        //     > input {
        //       width: 100%;
        //       @media screen and (max-width: 700px) {
        //       }
        //     }
        //   }
        // }
      }
    }
    @media screen and (max-width: 700px) {
      position: relative;
      padding: 0;
    }
  }
}
