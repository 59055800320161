.foodDescription-modal-wrapper {
  > .modal-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--background_color);
    box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
      rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
    position: relative;
    max-width: 550px;
    .fooddescription-carousel {
      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
    }

    > .header-close {
      position: absolute;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
      padding: 10px;

      > .icon {
        font-size: 24px;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: black;
        background-color: rgba(255, 255, 255, 0.651);
        cursor: pointer;
      }
    }
    > .content {
      margin-top: 16px;
      padding: 0px 20px 20px 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      > .header {
        display: flex;
        flex-direction: column;
        > .name-price {
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-direction: column;
          // align-items: center;
          > .name {
            font-family: var(--food_card_heading);
            font-size: 1.5em;
            font-weight: 600;
            word-wrap: break-word;
            word-break: break-word;
          }
          > .price {
            white-space: nowrap;
            font-family: var(--modals_sub_heading);
            text-align: right;
            line-height: 3rem;
            padding-left: 10px;
            font-weight: 700 !important;
          }
        }
        > p {
          font-family: var(--modals_description);
        }
      }
      > hr {
        margin: 0;
        width: 100%;
        border-top: 0.5px solid rgba(240, 240, 240, 0.096) !important;
      }
      > .tab-content {
        width: 100%;
        > .ingredients {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: 16px;
          > h6 {
            font-family: var(--modals_sub_heading);
          }
          > .icon {
            font-size: 24px;
            margin-bottom: 16px;
            color: var(--secondary_text_color);
          }
          > .contents {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            > p {
              padding: 0px 5px;
              text-align: center;
              font-family: var(--modals_description);
              margin: 0;
            }
          }
        }
        > .modifire_group_container {
          margin-bottom: 20px;
          &.dissable {
            pointer-events: none;
            opacity: 0.6;
          }
        }

        > .modifire_group_error {
          background-color: #ff00001a;
          border: 1px solid #ff00005e;
          border-radius: 2px;
        }
        > .duration-weight {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 16px;

          > .properties-container {
            display: flex;
            justify-content: center;
            gap: 3px;
            > img {
              width: 20px;
            }
          }
          > .specs {
            margin: 5px;
            display: flex;
            align-items: center;
            > .icon {
              font-size: 18px;
              margin-right: 8px;
            }
            > p {
              margin: 0;
              font-family: var(--modals_description);
            }
          }
        }

        > p {
          margin-top: 16px;
        }
      }

      > .special-contents {
        margin-top: 16px;
        margin-bottom: 2rem;
        > h4 {
          font-family: var(--modals_heading);
        }
        > p {
          font-family: var(--modals_description);
        }
        > textarea {
          width: 100%;
          min-height: 70px;
          outline: 0;
          background-color: transparent;
          font-family: var(--modals_description);
        }
      }
      > .button-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        > div {
          display: flex;
          align-items: center;
          > input {
            width: 50px;
            text-align: center;
            border: 1px solid rgb(197, 197, 197);
            outline: 0;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          > .primary {
            background-color: rgba(128, 128, 128, 0.205);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            border: none;
            text-align: center;
            margin: 10px;
            color: var(--primary_text_color);
          }
        }
        @media screen and (max-width: 470px) {
          flex-direction: column;
        }
      }
      > .recomnded-section {
        margin-top: 16px;
        margin-bottom: 2rem;
        > h6 {
          margin-bottom: 16px;
          font-family: var(--modals_sub_heading);
        }
        > .fooddescription--recomended-carousel {
          .foodcard-wrapper {
            display: flex;
            width: 100%;
            height: 100%;
            margin-top: 0px;
            padding-bottom: 0px;
            margin-right: 0;
            box-shadow: none;
            > .image-container {
              width: 38%;
              height: 100%;
              > img {
                height: 100%;
                object-fit: cover;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                border-top-right-radius: 0px;
              }
            }

            > .body {
              width: 60%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              > .weight {
                display: none;
              }
              > hr {
                display: none;
              }
              > div {
                padding: 0;
              }
              > .price {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    > .food_footer {
      margin-top: 16px;
      position: sticky;
      bottom: 0;
      z-index: 1055; /* [2] */
      @media screen and (max-width: 570px) {
        position: sticky;
        margin: 0;
        width: 100%;
      }
      > .secondary {
        width: 100%;
        border-radius: 0px;
        &.add_to_cart_button {
          display: flex;
          flex-direction: column;
        }
        &.clicked {
          line-height: 5px;
          color: white;
          background-color: var(--primary_button_color);
          > .icon {
            font-size: 24px;
            line-height: 5px;
            border: 1px solid white;
            border-radius: 15px;
          }
        }
      }
      > .footer-error {
        background: #ef4e4b;
        padding: 15px;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
