.login-page-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  position: relative;
  background-color: var(--background_color);
  // margin-top: 60px;
  > .close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 32px;
    padding: 3px;
    border: 1px solid var(--primary_text_color);
    border-radius: 50%;
    cursor: pointer;
    color: var(--primary_text_color);
  }
  > h1 {
    cursor: pointer;
    font-size: 40px;
    // color: grey;
    font-family: var(--auth_heading);
  }

  > form {
    > h4 {
      text-align: left;
      width: 100%;
      margin-top: 32px;
      font-family: var(--auth_sub_heading);
    }
    > .error-message {
      color: red;
      background-color: rgba(255, 0, 0, 0.212);
      padding: 5px 10px;
      border-radius: 4px;
      margin: 0;
      text-align: left;
      font-family: var(--auth_description);
    }
    > .success-message {
      // color: rgb(10, 51, 28);
      background-color: rgba(0, 255, 115, 0.281);
      padding: 5px 10px;
      border-radius: 4px;
      margin: 0;
      text-align: left;
      margin-bottom: 5px;
      margin-top: 5px;
      font-family: var(--auth_description);
    }
    min-width: 400px;
    > div {
      display: flex;
      flex-direction: column;
      > p {
        margin: 0;
        margin-bottom: 7px;
        margin-top: 16px;
        font-family: var(--auth_description);
      }
      > input {
        height: 40px;
        font-family: var(--auth_description);
        outline: 0;
        border-radius: 7px;
        // border: 1px solid rgba(128, 128, 128, 0.274);
        padding: 10px;
      }
      > .input-wrapper {
        border: 1px solid rgba(128, 128, 128, 0.486);
        display: flex;
        align-items: center;
        border-radius: 7px;
        margin-top: 5px;
        > .icon {
          font-size: 18px;
          color: rgba(128, 128, 128, 0.603);
          margin-left: 10px;
          margin-right: 10px;
          &.password {
            cursor: pointer;
            color: rgb(128, 128, 128);
          }
        }
        > input {
          flex: 1;
          border: none;
          background-color: transparent;
          font-family: var(--auth_description);
          height: 40px;
          outline: 0;
          font-size: 16px;
        }
        &.error {
          border: 1px solid red;
        }
      }
    }
    > button {
      width: 100%;
      // border: none;
      // outline: 0;
      // height: 40px;
      // background-color: var(--primary_button_color);
      // border-radius: 7px;
      // color: white;
      margin-bottom: 16px;
    }
    > p {
      text-align: right;
      > a {
        font-size: 14px;
        font-family: var(--auth_description);
      }
    }
  }
  > p {
    font-family: var(--auth_description);
  }
}
@media (max-width: 500px) {
  .login-page-wrapper > form {
    min-width: 300px;
  }
}
@media (max-width: 330px) {
  .login-page-wrapper > form {
    min-width: 250px;
  }
}
