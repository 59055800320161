.stripe-checkout-form {
  form {
    margin-top: 32px;
    > .main-button {
      margin-top: 16px;
      display: flex;
      align-items: center;
    }
  }
  .stripe-checkout {
    margin-top: 1rem;
    &.error {
      color: red;
      background-color: rgba(255, 0, 0, 0.185);
      padding: 0.5rem;
      font-weight: 700;
    }
  }
  .additional-fee-msg {
    margin-top: 5px;
    font-size: 0.9rem;
  }
}
