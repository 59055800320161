@import '../../../variables';

.go-to-main-website-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-content: center;

  > .logo-img {
    >img{
      cursor: pointer;
    }
  }

  > .powerdBy {
    > p {
      color: white;
      text-align: center;
    }
  }

  > .learn-more-btn {
    background: var(--primary_button_color);
    border: none;
    border-radius: 5px;
    font-size: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--button_text_color);
    padding: 10px 15px;;
  }
}
