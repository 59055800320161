.horizintal-category-bar-wrapper {
  display: flex;
  background-color: var(--background_color);
  align-items: center;
  width: 100%;
  // display: none;
  z-index: 200;
  position: sticky;
  top: 60px;
  justify-content: center;
  > .contents {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 7px;
    padding: 20px 0px;
    box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
      rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
    // min-height: 50px;
    > .icon {
      cursor: pointer;
      margin: 0px 10px;
    }
    > .react-multi-carousel-list {
      width: 100%;
      // background-color: cadetblue;
      height: 100%;
      display: flex;
      // justify-content: center;
      // background-color: cadetblue;
      > ul {
        > li {
          width: fit-content !important;
          padding: 0px 10px;
          white-space: nowrap;
          border-right: 1px solid rgba(128, 128, 128, 0.295);
          a {
            // margin: 0 !important;
            text-decoration: none;
            font-size: 16px;
            // margin: 0px 10px;
            > p {
              margin: 0;
              font-size: 14px;
              text-transform: uppercase;
              font-family: var(--category_bar_links);
              font-weight: 600;
            }
            &.selected {
              > p {
                color: var(--primary_button_color);
              }
            }
          }
        }
      }
    }
  }

  // @media screen and (max-width: 1000px) {
  //   display: flex;
  // }
}
#horizintal-category-bar{
  direction: ltr !important;
}