@import "../../variables";
.main-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.max-width-wrapper {
  width: 100%;
  max-width: $appMaxWidth;
}
.modal-dialog {
  border-radius: 0 !important;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  > .modal-content {
    border: none !important;
    input {
      font-family: var(--modals_description);
    }
  }
  @media screen and (max-width: 550px) {
    margin: 0 !important;

    .modal-content {
      border-radius: 0 !important;
    }
  }
}
.cart-wrapper {
  display: flex;
  padding: 10px 15px;
  position: fixed;
  top: calc(100vh - 200px);
  right: 16px;
  z-index: 700;
  transition: ease 0.5s;
  &:hover {
    top: calc(100vh - 205px);
  }
  > button.cartIcon {
    padding: 0;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    outline: none !important;
    border-radius: 2.8rem;
    position: relative;
    box-shadow: rgb(100 100 111 / 20%) 0px 4px 10px 4px;
    > .cart {
      margin: 0px 10px;
      width: 30px;
      height: 30px;
      object-fit: cover;
      color: gray;
    }
    > .dot {
      background: #f56565;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.8rem;
      position: absolute;
      right: 0;
      top: 0;
      box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
      font-size: 14px;
      color: #fff;
    }
  }
}
.hot-action-wrapper {
  display: flex;
  padding: 10px 15px;
  position: fixed;
  top: calc(100vh - 100px);
  right: 16px;
  z-index: 800;
  transition: ease 0.5s;
  font-family: var(--hot_action_requests_button);
  &:hover {
    top: calc(100vh - 105px);
  }
  > button.hot-action-icon {
    display: flex;
    column-gap: 0.5em;
    justify-content: space-around;
    outline: none;
    border: none;
    border-radius: 30px;
    padding: 0.7em 1.2em;
    background: var(--primary_button_color);
    color: var(--button_text_color);
    font-weight: 700;
    align-items: center;
    box-shadow: rgb(100 100 111 / 30%) 0px 4px 10px 4px;

    > .cart {
      margin: 0px 10px;
      width: 30px;
      height: 30px;
      object-fit: cover;
      color: gray;
    }
    > .dot {
      background: #f56565;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.8rem;
      position: absolute;
      right: 0;
      top: 0;
      box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
      font-size: 14px;
      color: #fff;
    }
  }
}
.hot-action-wrapper.disable {
  pointer-events: none;
  opacity: 0.4;
}
