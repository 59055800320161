@import './../../../variables';
.home-hero-section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  // padding-top: 80px;
  > .home-hero-banner-wrapper {
    width: 100%;
    position: relative;
    display: block;
    // min-height: 10px;
    // min-height: 200px;
    // height: 100vh;
    background-color: grey;
    max-height: max-content;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    // padding-top: 60px;
    > img {
      object-fit: contain;
      width: 100%;
    }
  }
  > .content {
    position: absolute;
    width: 100%;
    height: 100%;
    // top: 40px;

    background-color: rgba(0, 0, 0, 0.418);
    // background-color: crimson;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    // padding-top: 60px;
    // padding-top: 40px;
    > div {
      > .text-section {
        width: 100%;
        max-width: $appMaxWidth;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //   display: flex;
        > h1 {
          // max-width: 45%;
          padding: 10px 0;
          color: white;
          font-weight: 700;
          font-family: var(--hero_section_heading);
        }
        > h5 {
          font-weight: 600;
          color: white;
          font-family: var(--hero_section_sub_heading);
        }
        > hr {
          overflow: visible; /* For IE */
          padding: 0;
          border: none;
          border-top: medium double white;
          color: white;
          text-align: center;
          width: 50%;
          &:after {
            content: '§';
            display: inline-block;
            position: relative;
            top: -0.7em;
            font-size: 1.5em;
            padding: 0 0.25em;
            background: transparent;
          }
        }
        > p {
          max-width: 600px;
          color: rgba(255, 255, 255, 0.747);
          letter-spacing: 0.5px;
          margin-bottom: 32px;
          font-weight: 600;
          font-family: var(--hero_section_description);
        }
        > button {
          margin-top: 16px;
        }
        @media screen and (max-width: 1000px) {
          > p {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .home-hero-section-wrapper {
    margin-bottom: 16px;
    // padding-top: 60px;
    > .home-hero-banner-wrapper {
      order: 2;
      padding-top: 0px;
    }
    .content {
      display: flex;
      top: 0px;
      position: relative;
      order: 1;
      background-color: var(--background_color);
      .text-section {
        // margin-top: 32px;
        > h1 {
          // color: black !important;
          color: var(--primary_text_color) !important;
          padding-bottom: 0 !important;
          font-weight: 400 !important;
        }
        > h5 {
          color: var(--secondary_text_color) !important;
        }
        > hr {
          color: grey !important;
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .home-hero-section-wrapper {
    .content {
      margin-top: 0px;
    }
  }
}
