@import '../../../variables';
.home-testimonials-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  background-color: #f9f9f9;
  padding-top: 64px;
  padding-bottom: 64px;
  > hr {
    margin-top: 32px;
    width: 100px;
    border: 2px solid var(--primary_button_color);
  }
  > p {
    margin-top: 32px;
    max-width: 500px;
    text-align: center;
  }
  > div {
    margin-top: 32px;
    width: 90%;
    max-width: $appMaxWidth;
  }
  .testimonialCard-wrapper {
    width: 300px;
    padding: 20px;
    background-color: var(--primary_button_color);
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    > p {
      color: white;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        > img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      > .quotemark {
        font-size: 24px;
        color: #ffb136;
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .home-testimonials-wrapper {
    .testimonialCard-wrapper {
      width: 85%;
    }
  }
}
