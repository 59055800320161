.btn-group-container{
    button{
            box-sizing: border-box;
            width: 90px;
            margin-right: 12px;
            display: inline-flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            appearance: none;
            outline: none;
            user-select: none;
            cursor: pointer;
            border: 1px solid rgb(230, 3, 75);
            color: rgb(230, 3, 75);
            background-color: transparent;
            border-radius: 100px;
            padding: 12px 16px;
    }
    button:hover{
        background-color: #e6034b33
    }
    .active-button{
        background-color: #e6054b1f;
    }
}