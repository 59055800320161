.discountNotificationCard {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    margin: 0.8rem 0rem;
    background-color:black;
    // background-color: var(--background_color);
    border-radius: 3px;
    // box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
    //   rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
    position: relative;
    > .close-icon {
      position: absolute;
      top: -8px;
      right: -8px;
      font-size: 22px;
      padding: 3px;
      border: none;
      border-radius: 50%;
      background-color: #ce2929;
      color: white;
      cursor: pointer;
      &:hover {
        background-color: #a3a3a3;
      }
    }
    > .right {
      width: 100%;
      align-content: center;
      align-items: center;
      padding-left: 14px;
      > .food-details {
        display: flex;
        justify-content: space-between;
        > div > .discount {
          > .name {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 10px;
          }
          > .value {
            background-color: #5ac15e;
            border-radius: 10px;
            padding: 1px 5px 1px 5px;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            width: fit-content;
            margin-bottom: 10px;
            > span svg{
              margin-bottom: 4px;
              margin-right: 2px;
            }
          }
        }
        > .price {
          font-family: var(--food_card_sub_heading);
          font-weight: 700;
          align-self: center;
          color: var(--button_text_color);
          background-color: var(--primary_button_color);
          padding: 1px 5px 1px 5px;
          border-radius: 10px;
          height: 100%;
          font-size: 12px;
        }
        > .name {
          font-family: var(--food_card_sub_heading);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: fit-content;
          margin-right: 1px;
        }
      }
      > p {
        margin: 0;
        font-family: var(--food_card_description);
      }
    }
    > .left {
      width: 20%;
      display: flex;
      align-items: center;
      align-self: center;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        min-height: 10px;
        position: relative;
        object-fit: cover;
        border-radius: 2px 35px 35px 2px;
      }
    }
  }
  .rnc__notification-item{
    box-shadow: 0 0 15px rgb(0 0 0 / 50%) !important; 
    display: block !important;
  }