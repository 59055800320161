.button-loader {
  display: inline-block;
  position: relative;
  width: 38px;
  // width: fit-content;
  // display: flex;
  // justify-content: center;
  height: 20px;
}
.button-loader div {
  display: inline-block;
  position: absolute;
  // left: 8px;
  width: 10px;
  background: rgb(5, 5, 5);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.button-loader div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}
.button-loader div:nth-child(2) {
  left: 14px;
  animation-delay: -0.12s;
}
.button-loader div:nth-child(3) {
  left: 28px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 30px;
  }
  50%,
  100% {
    top: 10px;
    height: 10px;
  }
}
