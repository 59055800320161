.--NOTFOUNDPAGE{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    min-height: 100vh;
    &_CONTENT{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        >ing{
            max-height: 2rem;
            object-fit: contain;
            margin-bottom: 1rem;
        }
        a{
            text-decoration: none;
        }
    }
    &_FOOTER{
        padding: 1rem;
        display: flex;
        justify-content: center;

    }
}