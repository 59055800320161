.order-details-card-wrapper {
  padding: 20px 10px;
  // border-radius: 7px;
  // border-top: 1px solid rgba(231, 231, 231, 0.795);
  // margin-top: 20px;
  // box-shadow: rgb(0 0 0 / 24%) 0px 3px 14px;
  background-color:#f2f2f2;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  >.cancel-badge{
    background-color: red;
  }
  > .top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    > h6 {
      margin: 0;
      font-weight: 600 !important;
      // color: white;
      > span {
        // color: grey;
        // color: white;
        font-size: 1rem;
      }
    }
  }
  > .bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    > p {
      margin: 0;
      font-size: 14px;
      // color: white;
    }
    > h6 {
      margin: 0;
      font-size: 14px;
      color: var(--secondary_text_color);
      // color: white;
      font-weight: 600;
    }
  }
}
