@import '../../variables';
.home-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background_color);
  > section {
    width: 100%;
  }
  > .css-1wlnen7 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
