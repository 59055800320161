.--CUSTOMOPTIONS {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 32px;
  &-OPTION {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fbf9f961;
    padding: 10px;
    border-bottom: 1px solid #cccccc7d;
    cursor: pointer;
    > h6 {
      margin: 0;
    }
  }
  > .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    > button {
      width: 50%;
      margin-top: 10px;
    }
    > .cancel-button {
      padding: 10px 25px;
      font-family: "DM Sans", Sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #a7a4a4;
      background-color: transparent;
      //   border: 1px solid #d1d1d1;
      border: none;
      border-radius: 4px;
      outline: 0;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
}
