.foodcard-wrapper {
  border-radius: 8px;
  width: 270px;
  cursor: pointer;
  text-align: left;
  background-color: var(--background_color);
  padding-bottom: 16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 32px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  > .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 120px;
    padding:10px;
    border-bottom:1px solid #9f9f9f29;
    > .properties-container {
      right: 0px;
      position: absolute;
      bottom: 0px;
      margin: 3px;
      > img {
        width: 30px;
        margin-left: 0.4em;
      }
    }
    > span {
      border-bottom-right-radius: 3px;
      border-top-left-radius: 7px;
      background-color: white;
      position: absolute;
      padding: 4px;
    }
    > img {
      width: 100%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      object-fit: cover;
      height: 100%;
    }
  }
  > .body {
    display: grid;
    grid-template-rows: repeat(4, auto);
    flex: 1;
    > h6 {
      padding: 7px 7px 0px 7px;
      font-family: var(--food_card_heading);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
    > .markdown {
      padding: 0px 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px !important;
      margin: 0px 5px;
      margin-top: 5px;
      // height: 40px;
      font-family: var(--food_card_description);
      > p,
      ul {
        font-family: var(--food_card_description);
        font-size: 16px !important;
      }
    }
    > div {
      padding: 5px 10px 0px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        > p {
          margin: 0;
          margin-left: 5px;
          font-family: var(--food_card_description);
          // margin-bottom: 0.4rem;
        }
      }
      > .tag {
        background-color: var(--primary_button_color);
        color: white;
        padding: 5px 10px;
        border-radius: 7px;
        font-size: 14px;
        margin: 0 !important;
      }
    }
    > .weight {
      > .value {
        display: none;
        margin: 0;
        font-weight: 600;
      }
      > div {
        min-height: 24px;
      }
    }
    > .price {
      display: flex;
      align-items: center;
      margin-right: 10px;
      // justify-content: space-between;
      > .value {
        font-family: var(--food_card_sub_heading);
        margin-bottom: 0;
        margin-left: 10px;
        font-weight: 600;
        // font-size: 14px;
      }
      > .buy {
        background-color: var(--secondary_text_color);
        color: white;
        margin: 0;
        padding: 5px 10px;
        border-radius: 50%;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: rgba(100, 100, 111, 0.411) 0px 7px 29px 0px;
  }
}
.sold-out {
  pointer-events: none;
  opacity: 0.7;
}
@media screen and (max-width: 860px) {
  .foodcard-wrapper {
    > .image-container {
      padding:0;
      border-bottom:none;
      > .properties-container {
        > img {
          width: 1.5em;
          margin-left: 0.3em;
        }
      }
    }
  }
}
