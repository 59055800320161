@import '../../../variables';
.about-section-wrapper {
  width: 100%;
  max-width: $appMaxWidth;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding: 32px 20px;
  align-items: center;
  > .about-text-section {
    width: 50%;
    align-content: center;
    align-items: center;
    padding: 0px 15px 0px 15px;
    > h5 {
      margin-bottom: 0.5rem;
      color: var(--secondary_text_color);
      font-family: var(--about_section_sub_heading);
    }
    > h2 {
      margin-bottom: 20px;
      font-family: var(--about_section_heading);
    }
    > p {
      font-family: var(--about_section_description);
    }
  }
  > .about-img-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    > .about-img-sub-section {
      > .main-img {
        margin-bottom: 20px;
        width: 100%;
        // display: none;
        > img {
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
          vertical-align: middle;
        }
      }
      > .about-img-carousel {
        width: 100%;
        .react-multi-carousel-list {
          .react-multi-carousel-item {
            // padding: 8px;
            img {
              max-width: 100%;
              height: 100%;
              max-height: 150px;
              position: relative;
              object-fit: cover;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .about-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    > .about-text-section {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
    > .about-img-section {
      width: 100%;
      > .about-img-sub-section {
        > .main-img {
          margin-bottom: 20px;
          width: 100%;
          // display: none;
          > img {
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
