.modal-content {
  > .hot-actions-container {
    padding: 20px;
    border-radius: 8px;
    background-color: var(--background_color);
    box-shadow: 0px 25px 50px -12px #00000040;

    > .close-hot-actions {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 22px;
      padding: 3px;
      border: none;
      border-radius: 50%;
      background-color: #a3a3a3;
      color: white;
      cursor: pointer;
    }

    > h3 {
      font-family: var(--hot_action_requests_heading);
    }

    > .hot-action-items-container {
      margin: 1em 0 1em 0;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
