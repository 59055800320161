.PERSONAL_NOT_ALLOWED_PAGE-- {
  > .content {
    height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    > .innerBox {
      width: calc(100% - 6.4rem);
      max-width: 52rem;
      padding-bottom: 5rem;
      > img {
        width: 5rem;
      }
      > h1 {
        margin: 0;
        margin-top: 3.2rem;
        // font: 600 2rem/2.4rem Lexend, sans-serif;
      }
      > .descr {
        margin-top: 1.6rem;
        color: #000000a3;
        // font: 400 1.4rem/2rem Lexend, sans-serif;
      }
      > .helpline {
        margin-top: 1.6rem;
        color: rgba(0, 0, 0, 0.36);
        // font: 400 1.4rem/2rem Lexend, sans-serif;
        > a {
          color: #26aadd;
        }
      }
    }
  }
}
