$appMaxWidth: 1300px;
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: var(--primary_text_color);
  font-family: 'Roboto', sans-serif;
}
h1 {
  color: #001a07;
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  line-height: 1.3em;
  color: var(--primary_text_color);
}
p {
  margin-bottom: 0.9rem;
  font-size: 16px;
  opacity: 0.8;
  color: var(--primary_text_color);
}

h5 {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 1em;
  color: var(--primary_text_color);
}
h6 {
  color: black;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1em;
  color: var(--primary_text_color);
}
h4 {
  color: #001a07;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1em;
  color: var(--primary_text_color);
  font-size: calc(1.2rem + 0.3vw);
}
h3 {
  color: #001a07;
  font-size: calc(1.3rem + 0.6vw);
  font-size: 1.75em;
  font-weight: 500;
  color: var(--primary_text_color);
  line-height: 1em;
}
h2 {
  color: #001a07;
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
  line-height: 1em;
  color: var(--primary_text_color);
}
.main-button {
  width: auto;
  padding: 10px 25px;
  font-family: 'DM Sans', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--primary_button_color);
  border: 1px solid var(--primary_button_color);
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  transition: 1s all;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button_text_color);
}
:disabled {
  cursor: not-allowed;
}
.main-button:hover {
  background-color: var(--primary_button_color);
}
.remove-button {
  width: auto;
  padding: 4px 8px;
  font-family: 'DM Sans', Sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid rgb(231, 15, 15);
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  color: rgb(231, 15, 15);
}
.remove-button:hover {
  box-shadow: inset 30.5em 0 0 0 rgb(231, 15, 15);
  color: white;
}
.main-light-button {
  width: auto;
  padding: 10px 25px;
  font-family: 'DM Sans', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary_button_color);
  // background-color: var(--button_text_color);
  border-radius: 4px;
  border: none;
}
textarea,
input {
  font-family: 'DM Sans', Sans-serif;
  padding: 0.5rem;
  // color: grey;
  border-radius: 0.5rem;
  color: var(--primary_text_color);
  border: 1px solid var(--primary_text_color);
  background-color: var(--background_color);
}
