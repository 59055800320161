.order-details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // @media screen and (max-width: 470px) {
  //   min-height: 100vh;
  // }
  .modal-content {
    // max-width: 470px;
    height: 100%;
    // overflow-y: hidden;
    background-color: var(--background_color);
    // @media screen and (max-width: 470px) {
    //   // min-height: 100vh;
    // }

    > .order-details-modal-content {
      height: 100%;
      // overflow-y: scroll;
      // border: 1px solid red;
      > .header {
        padding: 20px 10px 0px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        > img {
          width: 70%;
        }
        > h4 {
          // position: absolute;
          width: 100%;
          padding: 10px 0px;
          font-weight: 600;
        }
      }
      > .orders {
        // margin-top: -90px;
        // padding: 10px;
        // padding-top: 30px;
        // border-top-right-radius: 45px;
        // border-top-left-radius: 45px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        // background-color: cadetblue;
        padding: 10px;
        // min-height: 100vh;
      }
    }

    > .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 22px;
      padding: 3px;
      // border: 1px solid grey;
      border: none;
      border-radius: 50%;
      background-color: #a3a3a3;
      color: white;
      cursor: pointer;
    }
  }
}
