.OrdersCountModal-wrapper {
  position: fixed;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  background-color: var(--primary_button_color);
  bottom: 5px;
  text-align: center;
  padding: 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  > h6 {
    color: white;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    > span {
      margin-left: 10px;
      background-color: white;
      //   padding: 5px;
      border-radius: 15px;
      width: 25px;
      height: 25px;
      display: flex;
      color: black;
      align-items: center;
      justify-content: center;
    }
  }
}
