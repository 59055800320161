@import "../../../variables";
.why-choose-section-wrapper {
  width: 100%;
  max-width: $appMaxWidth;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  // padding: 32px 20px;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;

  > .why-choose-img-section {
    width: 50%;
    display: flex;
    position: relative;
    margin-right: 15px;
    > img {
      // width: 88%;
      object-fit: cover;
      border-radius: 5px;
      vertical-align: middle;

      width: 100%;
    }
  }
  > .why-choose-text-section {
    width: 50%;
    align-content: center;
    align-items: center;
    padding: 0px 15px 0px 15px;
    > h5 {
      color: var(--secondary_text_color);
      margin-bottom: 0.5rem;
      font-family: var(--why_choose_us_section_sub_heading);
    }
    > h2 {
      margin-bottom: 20px;
      font-family: var(--why_choose_us_section_heading);
    }
    > p {
      font-family: var(--why_choose_us_section_description);
    }
    > .widget-container {
      display: flex;
      padding: 10px 0px;
      margin-bottom: 20px;
      > .icon-box {
        width: 80px;
        height: 80px;
        border-radius: 7px;
        margin-right: 16px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      > .icon-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > h4 {
          margin-bottom: 7px;
        }
        > p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .why-choose-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    > .why-choose-img-section {
      width: 100%;
      margin-bottom: 20px;
      // padding: 0 20px;
      margin-right: 0;
      > img {
        width: 100%;
        height: auto;
      }
    }
    > .why-choose-text-section {
      width: 100%;
      align-content: center;
      align-items: center;
      margin-left: 0px;
      margin-top: 20px;
      > h5 {
        margin-bottom: 0.5rem;
      }
      > h2 {
        margin-bottom: 20px;
      }
      > .widget-container {
        display: flex;
        padding: 10px 0px;
        margin-bottom: 20px;
        > .icon-box {
          //    width: 30%;
          font-size: 45px;
          color: #fff;
          background-color: var(--primary_button_color);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          border-radius: 10px;
          height: fit-content;
          padding: 15px;

          > span {
            margin: 0;
            padding: 0;
          }
        }
        > .icon-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          > h4 {
            margin-bottom: 7px;
          }
          > p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
