.foodCategory-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  > div {
    width: 100%;
    > p {
      // padding: 0;
      margin: 0;
      word-wrap: break-word;
    }
    > hr {
      width: 100%;
      border: 1px solid rgba(128, 128, 128, 0.329);
    }
    > h4 {
      font-family: var(--category_bar_links);
    }
    > .foods-section {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      > .foodcard-wrapper {
        width: 230px;

        > img {
          height: 120px;
        }
        > .body {
          > p {
            // display: none;
          }
          > hr {
            margin: 0;
            display: none;
          }
        }

        @media screen and (max-width: 1130px) {
          width: 210px;
          margin-left: 5px;
          margin-right: 5px;
        }
        @media screen and (max-width: 900px) {
          width: 230px;
          margin-left: 10px;
          margin-right: 10px;
        }
        @media screen and (max-width: 770px) {
          width: 210px;
          margin-left: 5px;
          margin-right: 5px;
        }
        @media screen and (max-width: 680px) {
          width: 200px;
          > .body {
            > .weight {
              display: none;
            }
            > div {
              // padding: 0px 10px 0px 10px;
            }
          }
        }
        @media screen and (max-width: 650px) {
          width: 180px;
        }
        @media screen and (max-width: 590px) {
          width: 210px;
        }
        @media screen and (max-width: 470px) {
          // width: 180px;
        }
        @media screen and (max-width: 470px) {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 8px;
          padding-bottom: 0px;
          border-radius: 7px;
          > .image-container {
            width: 38%;
            > img {
              height: 100%;
              object-fit: cover !important;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
              border-top-right-radius: 0px;
            }
          }

          > .body {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > div {
              padding: 0;
            }
            > .price {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
