.button-circuler-spin-loader {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
.button-circuler-spin-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 0;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: button-circuler-spin-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: var(--primary_text_color) transparent transparent transparent;
}
.button-circuler-spin-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.button-circuler-spin-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.button-circuler-spin-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes button-circuler-spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
