.singleItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin: 0.5rem 0rem;
  background-color: #f2f2f2;
  border-radius: 8px;
  > .right {
    width: 72%;
    align-content: center;
    align-items: center;
    padding-left: 14px;
    > .food-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > h6 {
        font-family: var(--food_card_sub_heading);
      }
    }
    > p {
      margin: 0;
      font-family: var(--food_card_description);
    }
    > .remove-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  > .left {
    width: 26%;
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      min-height: 70px;
      max-height: 87.75px;
      position: relative;
      object-fit: cover;
      border-radius: 2px 35px 35px 2px;
    }
  }
}
