.cart-orders-wrapper {
  margin-top: 1rem;
  > h4 {
    font-family: var(--modals_heading);
  }
  > .add-more {
    background-color: transparent;
    border: none;
    margin: 10px 0px;
    display: flex;
    justify-content: flex-start;
    color: var(--secondary_text_color);
    font-family: var(--modals_description);
  }
  > .info-container{
    margin-top: 10px;
    border-radius: 4px;
    background-color:var(--primary_button_color);
    padding: 10px;
    color:var(--button_text_color);

    &.expanded{
      border-radius: 4px 4px 0px 0px;
    }

    >p {
      color:var(--button_text_color);
    }
    >.link{
      cursor: pointer;
      text-decoration: underline;
    }

  }

  >.hours-wrapper{
    padding: 10px;
    border-bottom: 1px solid var(--primary_button_color);
    border-left: 1px solid var(--primary_button_color);
    border-right: 1px solid var(--primary_button_color);
    border-radius: 0px 0px 4px 4px;
  }
  > .tips-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem 0;
    > div {
      > h6 {
        font-family: var(--modals_sub_heading);
      }
      > button {
        background-color: #e2e2e2;
        // border: 1px solid var(--primary_button_color);
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        border: none;
        border-radius: 0.7rem;
        transition: 0.25s all;
        &:hover {
          background-color: var(--primary_button_color);
          color: white;
        }
      }
    }
    > input {
      margin-top: 1rem;
      height: 4rem;
      padding: 0.5rem;
      border: 1px solid var(--primary_text_color);
      background-color: var(--background_color);
      outline: 0;
    }
  }
  > h4 {
    margin-left: 15px;
  }
  > .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    > img {
      width: 80%;
      height: 300px;
      object-fit: contain;
    }
  }
  > .foodCardList {
    margin: 1rem 0rem;
    // max-height: calc(100vh - 250px);
    // overflow-y: auto;
  }
  > .netAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 15px;
    margin-top: 16px;
    // margin-bottom: 30px;
    flex-direction: column;
    h5 {
      font-family: 'DM Sans', Sans-serif;
      font-size: 16px;
    }
    > .subSec {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      //   margin-top: 5px;
      padding: 10px;
      > h6 {
        padding: 0;
        margin-bottom: 0;
        font-weight: 500;
        font-family: var(--modals_description);
      }
    }
    > .total {
      border-top: 1px solid rgba(128, 128, 128, 0.445);
      padding: 10px;
      h4 {
        font-size: 20px;
        font-family: var(--modals_description);
      }
    }
    > button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
