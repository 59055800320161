@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&family=Space+Grotesk:wght@600&display=swap");
@import "react-multi-carousel/lib/styles.css";

body {
  margin: 0;
  padding-right: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --appMaxWidth: 1300px;
  --primary_button_color: #2ca65d;
  --background_color: white;
  --secondary_text_color: #ffb136;
  --nav_bar_color: black;
  --primary_text_color: black;
  --button_text_color: #ffffff;
  --red: #f80000;

  /* home page || Menu page  */
  --hero_section_heading: "Cormorant Upright", serif;
  --hero_section_sub_heading: "Lobster Two", cursive;
  --hero_section_description: "Roboto", sans-serif;
  /* about section  */
  --about_section_heading: "Cormorant Upright", serif;
  --about_section_sub_heading: "Lobster Two", cursive;
  --about_section_paragraph: "Roboto", sans-serif;
  /* most popular foods section  */
  --most_popular_foods_section_heading: "Cormorant Upright", serif;
  --most_popular_foods_section_description: "Roboto", sans-serif;
  /* why choose us section  */
  --why_choose_us_section_heading: "Cormorant Upright", serif;
  --why_choose_us_section_sub_heading: "Lobster Two", cursive;
  --why_choose_us_section_description: "Roboto", sans-serif;
  /* contact us section  */
  --contact_us_section_heading: "Cormorant Upright", serif;
  --contact_us_section_sub_heading: "Lobster Two", cursive;
  --contact_us_section_description: "Roboto", sans-serif;
  /* newsletter section  */
  --newsletter_section_heading: "Cormorant Upright", serif;
  --newsletter_section_sub_heading: "Roboto", sans-serif;
  --newsletter_section_placeholder: "Roboto", sans-serif;
  /* modals  */
  --modals_heading: "Cormorant Upright", serif;
  --modals_sub_heading: "Roboto", sans-serif;
  --modals_description: "Roboto", sans-serif;
  /* Food card  */
  --food_card_heading: "Roboto", sans-serif;
  --food_card_sub_heading: "Roboto", sans-serif;
  --food_card_description: "Roboto", sans-serif;
  /* Nav Bar  */
  --nav_bar_name: "Cormorant Upright", serif;
  --nav_bar_links: "Roboto", sans-serif;
  /* Category Bar links  */
  --category_bar_links: "Cormorant Upright", serif;
  /* login, reset password and signup Page */
  --auth_heading: "Cormorant Upright", serif;
  --auth_sub_heading: "Roboto", sans-serif;
  --auth_description: "Roboto", sans-serif;
  /* Hot action requests */
  --hot_action_requests_button: "Roboto", sans-serif;
  --hot_action_requests_heading: "Roboto", sans-serif;
  --hot_action_requests_item_header: "Roboto", sans-serif;
  --hot_action_requests_item_message: "Roboto", sans-serif;
  --hot_action_requests_send_request_button: "Roboto", sans-serif;
}
