.phoneInputBox {
  flex: 1 1;
  position: relative;
  >.PhoneInput > .PhoneInputInput {
    width: 100%;
    height: 50px;
    border: none;
    padding: 0px 0px 0px 15px;
    outline: 0;
    font-family: var(--contact_us_section_description);
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px
  }
  >.PhoneInput >.PhoneInputCountry{
      font-size: 14px;
    background-color: #ffffff;
    padding-left: 12px;
    padding-right: 8px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    margin-right: 0 !important;
  }
}
.formError > .PhoneInput > .PhoneInputInput {
  border: 1px solid red !important;
}
