.store-selector-wrapper {
  > .modal-content {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--background_color);
    > .inner-wrapper {
      margin: 20px;
      > .shops {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 32px;
        margin-bottom: 32px;
        justify-content: center;
        cursor: pointer;
        > .store-box {
          width: 170px;
          padding: 15px 5px;
          border: 0.2px dashed rgb(180, 179, 179);
          margin: 5px;
          border-radius: 7px;
          // transition: 0.2s ease-in-out;

          > .icon {
            margin-bottom: 7px;
            font-size: 32px;
            color: rgb(212, 212, 212);
          }
          > p {
            max-width: 150px;
          }

          h6 {
            word-wrap: break-word;
          }
          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 13px 0px;
            background-color: var(--primary_button_color);
            transform: scale(1.01);
            border: 1px solid var(--primary_button_color);
            > .icon {
              margin-bottom: 7px;
              font-size: 32px;
              color: rgb(212, 212, 212);
            }
            > p {
              color: white;
            }
            > h6 {
              color: white;
              font-weight: 600;
            }
          }
        }
      }
      > button {
        margin-top: 48px;
      }
      > .close {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
  }
}
