.navbar-wrapper {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: var(--nav_bar_color);
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: 0.5s ease-in-out;
  &.hide-navbar {
    display: none;
  }
  > .max-width-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    >.logo{
      cursor: pointer;
      > h5 {
        margin-left: 12px;
        font-size: 28px;
        cursor: pointer;
        white-space: nowrap;
        color: var(--secondary_text_color);
        font-weight: 700;
        font-family: var(--nav_bar_name);
      }
      >img{
        max-height: 42px;
        max-width: 100%;
      }
    }
  
    > #horizintal-home-navbar {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        > a {
          text-decoration: none;
          > p {
            padding: 0px 10px;
            color: var(--button_text_color);
            cursor: pointer;
            position: relative;
            margin: 0;
            font-family: var(--nav_bar_links);
            > span {
              position: absolute;
              left: 0;
              transform: translateX(5%);
              top: 30px;
              width: 90%;
              border-bottom: 1px solid white;
              display: none;
              transition: 1s ease-in-out;
            }
            &:hover {
              > span {
                display: block;
                transition: 1s ease-in-out;
              }
            }
          }
        }
        > p {
          padding: 0px 10px;
          color:var(--button_text_color);
          cursor: pointer;
          position: relative;
          margin: 0;
          font-family: var(--nav_bar_links);
          > span {
            position: absolute;
            left: 0;
            transform: translateX(5%);
            top: 30px;
            width: 90%;
            border-bottom: 1px solid white;
            display: none;
            transition: 1s ease-in-out;
          }
          &:hover {
            > span {
              display: block;
              transition: 1s ease-in-out;
            }
          }
        }
        > .signin {
          cursor: pointer;
          > p {
            margin: 0;
            color: var(--button_text_color);
            font-family: var(--nav_bar_links);
          }
        }
        > .dropdown {
          > button {
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            color: white;
            > p {
              margin: 0;
              color: var(--button_text_color);
              font-family: var(--nav_bar_links);
            }
          }
          > div {
            background-color: var(--background_color);
            :hover {
              color: var(--background_color);
              > button {
                color: var(--background_color);
                > p {
                  color: var(--button_text_color);
                }
              }
            }
            > button {
              display: flex;
              align-items: center;
              // background-color: transparent;
              border: none;
              outline: 0;
              > p {
                margin: 0;
                font-family: var(--nav_bar_links);
              }
            }
          }
        }
      }
    }

    > .more-icon {
      width: 30px;
      height: 50px;
      color: rgb(211, 211, 211);
      margin-right: 16px;
      display: none;
      cursor: pointer;
    }
    @media screen and (max-width: 800px) {
      > #horizintal-home-navbar > .nav-links-wrapper {
        display: none;
      }
      > .more-icon {
        display: block;
      }
    }
  }
}
#vertical-home-navbar {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.904);
  > .close-icon {
    color: white;
    width: 30px;
    height: 30px;
    margin: 30px;
    cursor: pointer;
  }
  > div {
    > .nav-links-wrapper {
      padding-top: 80px;

      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      > a {
        text-decoration: none;
        > p {
          margin-bottom: 32px;
          font-weight: 600;
          color: var(--button_text_color);
        }
      }
      > p {
        margin-bottom: 32px;
        font-weight: 600;
        color: var(--button_text_color);
      }
      > .dropdown {
        margin-bottom: 32px;
        > button {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          outline: 0;
          box-shadow: none;
          > p {
            margin: 0;
            color: var(--button_text_color);
          }
        }
        > div {
          > button {
            display: flex;
            align-items: center;
            // background-color: transparent;
            border: none;
            outline: 0;
            > p {
              margin: 0;
            }
          }
        }
      }
      > .signin {
        cursor: pointer;
        > p {
          margin: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  #vertical-home-navbar {
    display: block;
  }
}
