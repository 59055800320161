@import '../../variables';
.feedback-page-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--background_color);
  position: relative;
  >.cover-image-container{
    width: 100%;
    max-width: 1200px;
    margin-top: 10px;
    >img{
      width: 100%;
      object-fit: contain;
      border-radius: 7px;
    }
  }
  .feedback-container {
    // position: absolute;
    min-width: 330px;
    max-width: var(--appMaxWidth);
    min-height: 600px;
    box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
      rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
    text-align: center;
    padding: 20px;
    margin: 20px;
    max-width: 500px;
    border: 1px solid #ede0e0;
   
    
    > form {
      > .feedback-wrapper {
        min-height: 100%;
        // padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > .header-title {
          margin-bottom: 32px;
          font-family: var(--modals_heading);
        }
        .qs {
          margin-bottom: 25px;
          font-family: var(--modals_sub_heading);
        }
        .text-box-qs {
          padding-left: 15px;
          padding-right: 15px;
          min-width: 300px;
          border-radius: 20px;
          width: 100%;
          max-width: 300px;
        }
        .qs-submit {
          border-radius: 5px;
          position: absolute;
          bottom: 20px;
          transform: translate(-50%) !important;
          box-sizing: border-box;
          background-color: var(--primary_button_color);
          max-width: 300px;
          width: 100%;
          height: 50px;
          color: rgb(255, 255, 255);
          display: inline-flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          appearance: none;
          outline: none;
          user-select: none;
          cursor: pointer;
          border: 1px solid var(--primary_button_color);
          padding: 9px 12px;
        }
        .qs-submit button:disabled,
        button[disabled] {
          cursor: not-allowed;
          user-select: none;
          opacity: 0.3;
        }
  
        .required-text {
          color: var(--red);
         
        }
        .question-text {
          // width: 300px;
          overflow-wrap: break-word;
          font-family: var(--modals_sub_heading);

        }
      }
    }
  }
}

