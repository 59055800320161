.cart-modal-wrapper {
  max-width: 500px;
  margin-top: 80px;
  // max-height: calc(100vh - 200px);
  // background-color: var(--background_color);
  // box-shadow: rgba(151, 151, 151, 0.12) 0px 2px 4px 0px,
  //   rgba(124, 124, 124, 0.32) 0px 2px 16px 0px;
  display: flex;
  justify-content: center;
  .modal-content {
    max-width: 470px;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--background_color);
    box-shadow: 0px 25px 50px -12px #00000040;
    > .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 22px;
      padding: 3px;
      // border: 1px solid grey;
      border: none;
      border-radius: 50%;
      background-color: #a3a3a3;
      color: white;
      cursor: pointer;
    }
  }
  &.pay {
    .modal-content {
      padding: 0;
    }
  }
  .payment-processing-loader {
    width: 100% !important;
    position: relative;
    height: fit-content;
  }
}
