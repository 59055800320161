@import "../../variables";
.footer-wrapper {
  width: 100%;
  background-color: var(--nav_bar_color);
  padding-top: 30px;
  > .footer-container {
    width: 100%;

    > .row > .footer-box {
      color: var(--button_text_color);
      margin-bottom: 20px;
      > p {
        color: var(--button_text_color);
      }
      > .logo {
        cursor: pointer;
        margin-bottom: 10px;
        > h5 {
          font-size: 28px;
          cursor: pointer;
          white-space: nowrap;
          color: var(--secondary_text_color);
          font-weight: 700;
          font-family: var(--nav_bar_name);
        }
        > img {
          max-height: 42px;
          max-width: 100%;
        }
      }
      >.social-media{
        display: flex;
        gap: 8px;
        >.icon{
          padding: 2px;
          cursor: pointer;
        }:hover{
          background-color: var(--primary_button_color);
        }
      }
    }
    > .left-container {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        display: flex;
        > p {
          padding-right: 10px;
          color: var(--button_text_color);
        }
        > .socialMediaLinks {
          margin-right: 10px;
          font-size: 24px;
        }
      }
      > .links {
        margin-top: 32px;
      }
    }
    > .right-container {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        display: flex;
        > p {
          padding-right: 10px;
        }
        > .socialMediaLinks {
          margin-right: 10px;
          font-size: 24px;
        }
      }
      > .links {
        margin-top: 32px;
      }
    }
  }
  > .bottom-container {
    display: flex;
    justify-content: center;
  }
  > .bottom {
    margin-top: 32px;
    text-align: center;
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid rgba(196, 196, 196, 0.219);
    > p {
      color: var(--button_text_color);
    }
  }
}
@media screen and (max-width: 678px) {
  .footer-wrapper {
    > .footer-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > .left-container {
        width: 95%;
      }
    }
  }
}
