.promoCards-wrapper {
  width: 100%;
  justify-content: center;
  // background-color: #f7f6f2;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  align-items: center;
  .promoCard-carousel {
    width: 100%;
    max-width: 1180px;
    min-height: 100px;
    padding: 40px 0px;
    display: flex;
    // justify-content: center;
    .promoCard-container {
      max-width: 375px;
      height: 220px;
      background-size: cover;
      border-radius: 10px;
      cursor: pointer;
      margin: 0px 15px;
    }
    .promoCard {
      position: relative;
      display: flex;
      background-image: linear-gradient(
        to top,
        transparent 45%,
        rgba(0, 0, 0, 0.4) 100%
      );
      border-radius: 10px;
      padding: 10px;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);

      > .text {
        position: absolute;
        margin-left: 20px;
        margin-top: 20px;
        transition: 0.2s ease-in-out;
        > h4 {
          font-weight: bold;
          color: white;
        }
        > p {
          color: white;
        }
      }
      > img {
        object-fit: contain;
        width: 100%;
        border-radius: 10px;
      }
      &:hover {
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        // transform: scale(1.01);
      }
    }
  }
}

.promo-modal-wrapper {
  max-width: 500px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  .modal-content {
    max-width: 470px;
    border-radius: 8px;
    box-shadow: 0px 25px 50px -12px #00000040;
    > .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 22px;
      padding: 3px;
      background-color: #a3a3a3;
      border-radius: 50%;
      color: white;
      cursor: pointer;
    }
    > img {
      border-radius: 8px 8px 0px 0px;
    }
    > .promo-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      img {
        width: 64px;
      }
      > h3 {
        margin-top: 1rem;
        font-family: var(--modals_heading);
      }
      > p {
        color: gray;
        font-family: var(--modals_description);
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .promoCards-wrapper {
    .promoCard {
      flex-direction: column;
      > .right {
        display: none !important;
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .promoCards-wrapper {
    .promoCard {
      flex-direction: row;
      > .right {
        width: 40% !important;
        display: flex !important;
      }
    }
  }
}
