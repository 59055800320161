.modifier-card-wrapper {
  display: flex;
  background-color: #fbf9f961;
  padding: 10px;
  border-bottom: 1px solid #cccccc7d;
  > .action-btn {
    margin-right: 10px;
  }
  > p {
    margin: 0;
    margin-right: 10px;
    font-family: var(--modals_description);
  }
  > .name {
    flex: 1;
  }
  > .price {
    font-size: 12px;
    display: flex;
    align-items: center;
    > p {
      margin: 0;
    }
  }
  .button-container {
    display: flex;
    align-items: center;
    > button {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      border-radius: 50%;
      margin: 0px 10px;
      border: none;
      outline: 0;
      background-color: rgba(128, 128, 128, 0.205);
      color: var(--primary_text_color);
    }
    > p {
      margin: 0;
    }
  }
}
