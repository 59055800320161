.single-order-wrapper {
  padding: 20px 10px;
  margin-bottom: 10px;
  height: 100%;
  > .header {
    display: flex;
    gap: 10px;
    > div > p > .cancel-badge {
      background-color: red;
    }
  }

  > .steper-container {
    background-color: #f2f2f2;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px 10px 10px 10px;
  }
  > .footer-container {
    > .cancel-button {
      background-color: var(--themeColor);
      width: 105px;
      border: none;
      outline: 0;
      box-shadow: 2px 2px 7px #888888;
      color: #fff;
      font-weight: 400;
      padding: 0.375rem 0.75rem;
      font-size: 14px;
      height: 35px;
      line-height: 1.5;
      border-radius: 0.25rem;
    }
  }

  > .netAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    flex-direction: column;
    h5 {
      font-family: "DM Sans", Sans-serif;
      font-size: 16px;
    }
    > .subSec {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 10px;
      > h6 {
        padding: 0;
        margin-bottom: 0;
        font-family: var(--modals_description);
      }
    }
    > .total {
      border-top: 1px solid rgba(128, 128, 128, 0.445);
      padding: 10px;
      h4 {
        font-size: 20px;
        font-family: var(--modals_description);
      }
    }
    > button {
      width: 100%;
      margin-top: 16px;
    }
  }
  > .item-container {
    max-height: 40vh;
    overflow-y: scroll;
  }
  > .footer-container {
    display: flex;
    justify-content: flex-end;
  }
}

.body-container {
  padding: 30px;
  >h4{
    font-family: var(--modals_sub_heading);
  }
  >p{
    font-family: var(--modals_description);
  }
  > .btn-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
   
  }
}
