.modifire-header-container {
//   background-color:var(--primary_button_color);
  background-color:#d2cecca3;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  border-radius:3px 3px 0px 0px;
  > .title {
    font-weight: bold;
    margin: 0px !important;
  }
  >.tag{
    font-size: 12px;
    border-radius: 12px;
    border: 1px solid black;
    padding: 0px 7px;
    margin: 3px !important;
    height: fit-content;
  }
}
