.order-stepper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > .icon {
      font-size: 35px;
      color: var(--primary_button_color);
      border-radius: 50%;
      background-color: #fffdfd;
      // border: 1px solid rgba(128, 128, 128, 0.123);
      padding: 7px;
      // box-shadow: grey 0px 2px 13px 0px;
    }
    > p {
      margin: 5px;
      font-size: 16px;
      // color: rgb(197, 194, 194);
    }
  }
  .line{
    width: 100%;
    background-color: gray;
    height: 5px;
    margin-top: 14px;
  }
  > hr {
    flex: 1;
    border-bottom: 5px solid rgb(255, 255, 255);
    width: 100%;
  }
  > .active {
    > .icon {
      color: white;
      border: 2px solid white;
      background-color: var(--primary_button_color);
      box-shadow: white 0px 2px 13px 0px;
    }
    > p {
      // color: white;
      font-weight: 700;
    }
  }
  > .activehr {
    background-color: var(--primary_button_color);
  }
}
