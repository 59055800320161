.opening-hours {
    overflow: hidden;
    padding: 0.5em;
    > .time-range {
      display: flex;
      justify-content: space-between;
      font-size: small;
      padding: 2px;
      &.selected {
        background-color: var(--primary_button_color);
        color: var(--button_text_color);
        border-radius: 2px;
      }
    }
  }
  